import { useCallback, useEffect, useState } from 'react'
import { IFloatingCombo, IFloatingComboOption } from './interfaces'
import Icon from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/system/Icon'
import Button from '@/components/Actions/Button'
import { Typography } from '@pol-npm/riscos-financeiros-dashboard-ui/dist/components/base'
import * as S from './styles'
import { dashText } from '@/tools'

export function FloatingCombo({
  label,
  optionsLabel = label,
  options,
  error = false,
  errorMessage = '',
  emptySelectionLabel = 'Nenhum',
  filterButtonLabel = 'Filtrar',
  singleSelection = false,
  filterCallback,
  onChange = () => {},
  getLabelSelectedValue = () => null,
  children = <></>,
}: IFloatingCombo) {
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [selecteds, setSelecteds] = useState<IFloatingComboOption[]>([])
  const [comboLabel, setComboLabel] = useState<string>()

  const handleComboClick = () => {
    setShowOptions(prevShowOptions => !prevShowOptions)
  }

  const handleOptionClick = (clickedOption: IFloatingComboOption) => {
    invertOptionSelection(clickedOption)
  }

  const invertOptionSelection = (optionToInvert: IFloatingComboOption) => {
    const option = selecteds.find(selected => selected.value === optionToInvert.value)

    if (!option) {
      addSelectedOption(optionToInvert)
    } else {
      removeSelectedOption(optionToInvert)
    }
  }

  const addSelectedOption = (option: IFloatingComboOption) => {
    setSelecteds(selecteds => {
      if (singleSelection) {
        return [option]
      }

      return [...selecteds, option]
    })
  }

  const removeSelectedOption = (option: IFloatingComboOption) => {
    const filteredSelecteds = selecteds.filter(selected => selected.value !== option.value)

    setSelecteds(() => filteredSelecteds)
  }

  const isSelected = (option: IFloatingComboOption): boolean => {
    return !!selecteds.find(selected => selected.value === option.value)
  }

  const handleClickClearButton = () => {
    clearSelecteds()
    updateComboLabel([])
    setShowOptions(false)
    filterCallback([])
  }

  const clearSelecteds = () => {
    setSelecteds([])
  }

  const handleClickFilterButton = () => {
    if (error) {
      return
    }

    updateComboLabel(selecteds)
    setShowOptions(false)
    filterCallback(selecteds)
  }

  const handleOverlayClick = () => {
    setShowOptions(false)
  }

  const updateComboLabel = useCallback(
    selecteds => {
      const selectedsLabel =
        getLabelSelectedValue(selecteds) ?? selecteds.map(selected => selected.label).join(', ')

      const comboLabel = `${label}: ${selecteds.length > 0 ? selectedsLabel : emptySelectionLabel}`

      setComboLabel(comboLabel)
    },
    [emptySelectionLabel, getLabelSelectedValue, label],
  )

  useEffect(() => {
    onChange(selecteds)
  }, [onChange, selecteds])

  useEffect(() => {
    clearSelecteds()
  }, [options])

  useEffect(() => {
    updateComboLabel(selecteds)

    return () => {}
  }, [selecteds, updateComboLabel])

  return (
    <>
      {showOptions && <S.Overlay onClick={handleOverlayClick}></S.Overlay>}

      <S.ComboContainer
        style={{
          border: `1px solid ${
            error ? 'rgba(132,35,16,0.5)' : 'var(--border-border-input-neutral, #B3B3B3)'
          }`,
        }}
        onClick={handleComboClick}
        data-test-id={`filter-${label.toLocaleLowerCase()}`}
      >
        <S.ComboTextContainer>
          <S.ComboText>
            <Typography content={comboLabel} variant="porto-text-body-2-bold" />
          </S.ComboText>
          <Icon
            sx={{
              marginLeft: '5px',
            }}
            name="chevron_down"
          />
        </S.ComboTextContainer>

        {showOptions && (
          <S.FloatingContainer
            onClick={e => e.stopPropagation()}
            style={{
              border: `1px solid ${error ? 'rgba(132,35,16,0.5)' : 'transparent'}`,
            }}
          >
            <S.OptionsContentContainer>
              <Typography
                content={optionsLabel}
                variant="porto-title-5-semibold"
                sx={{
                  color: 'var(--neutras-black-75, #404040)',
                }}
              />

              <S.OptionsContainer>
                {options.map((option: IFloatingComboOption) => {
                  return (
                    <S.OptionContainer
                      key={option.value.toString()}
                      style={{
                        background: !isSelected(option)
                          ? 'var(--neutras-white, #FFF)'
                          : 'var(--seguros-porto-seguros-15, #D1EFFE)',
                      }}
                      onClick={() => {
                        handleOptionClick(option)
                      }}
                      data-test-id={dashText(`option-${option.label}`)}
                    >
                      <Typography
                        content={option.label}
                        variant="porto-text-body-2-bold"
                        sx={{
                          color: 'var(--neutras-black-75, #404040)',
                        }}
                      />
                    </S.OptionContainer>
                  )
                })}
              </S.OptionsContainer>

              <S.ChildrenContainer>{children}</S.ChildrenContainer>
            </S.OptionsContentContainer>

            <S.ActionsContainer>
              <S.ActionContainer>
                <Button
                  kind="ghost"
                  type="button"
                  onClick={() => {
                    handleClickClearButton()
                  }}
                  data-test-id="clean-filters"
                >
                  Limpar
                </Button>
                <Button
                  type="button"
                  onClick={handleClickFilterButton}
                  data-test-id="apply-filters"
                >
                  {filterButtonLabel}
                </Button>
              </S.ActionContainer>
            </S.ActionsContainer>
          </S.FloatingContainer>
        )}
      </S.ComboContainer>
      {!showOptions && error && errorMessage && (
        <S.ErrorContainer>
          <Typography
            content={errorMessage}
            variant="porto-text-caption-bold"
            sx={{
              color: 'var(--porto-primitive-red-100, #842310)',
            }}
          />
        </S.ErrorContainer>
      )}
    </>
  )
}
