import { formatMoneyBRL } from '@/tools'
import { getPercentFromOperationCode } from '@/tools/utils/commision'
import { formatToPhone, isCNPJ, isCPF } from 'brazilian-values'
import dayjs from 'dayjs'
import { occupations } from './Steps/occupations'

export const initialValues = {
  susep: '',
  partner: '',
  renewal_number: '',

  contract_plan: '',
  contract_clause: '',
  contract_period_start: '',
  contract_period_end: '',
  contract_discount: '',
  contract_prolabore: '',
  contract_commission: '',

  customer_document: '',
  customer_name: '',
  customer_email: '',
  customer_phone: '',
  customer_birthdate: '',
  customer_company_type: '',
  customer_company_netEquity: '',
  customer_company_grossIncome: '',
  customer_company_controllers: '3',
  civil_status: '',
  customer_income: '',
  customer_occupation: '' as unknown,
  customer_pep: '',

  pep_document_type: '',
  pep_document: '',
  pep_document_dispatcher: '',
  pep_document_expiration_date: '',
  pep_document_expedition_date: '',
  pep_nationality: '',
  pep_brazilian_resident: '',

  pep_cpf: '',
  pep_name: '',
  pep_relationship_type: '',

  company_name: '',
  foundation_date: '',
  company_phone: '',
  company_email: '',

  estate_type: '',
  estate_owner: true,
  estate_address: {
    city: '',
    state: '',
    number: '',
    street: '',
    zipcode: '',
    complement: '',
    neighborhood: '',
  },

  coverages: {
    fire: '' as string | number,
    content: '' as string | number,
    eletrical_damages: 0 as string | number,
    vehicle_impacts: 0 as string | number,
    gale: 0 as string | number,
    civil_responsability: 0 as string | number,
    rental_loss: 0 as string | number,
    riot: 0 as string | number,
  },

  beneficiaries: [] as Record<string, any>,
}

const parseOccupationValue = (value: string | number) => {
  const selected = occupations[value] as string | undefined

  return { value, label: selected }
}

// TIPAR QUANDO MERGIAR
export const parseContractToForm = (contract): typeof initialValues => {
  function validateDate(period: string) {
    const startDate = new Date(dayjs(contract?.period?.start).format())
    const endDate = new Date(dayjs(contract?.period?.end).format())

    if (period === 'start') {
      if (startDate >= new Date()) {
        return startDate
      } else {
        return new Date()
      }
    }

    if (period === 'end') {
      if (startDate >= new Date()) {
        return endDate
      }
    }
  }

  const isPJ = isCNPJ(contract.payload.customer.document)
  const isPF = isCPF(contract.payload.customer.document)

  return {
    ...initialValues,
    susep: contract?.broker,
    partner: contract?.partner,
    renewal_number: contract?.renewal_number,
    contract_plan: contract?.payload?.contract?.plan?.toLowerCase(),
    contract_clause: contract?.payload?.contract?.service_clause,
    contract_period_start: validateDate('start'),
    contract_period_end: validateDate('end'),
    contract_discount:
      contract?.payload?.contract?.discount === 0 ? '' : contract?.payload?.contract?.discount,
    contract_prolabore: contract?.payload?.contract?.prolabore,
    contract_commission: getPercentFromOperationCode(contract?.payload?.contract?.operation_code),

    customer_document: contract.payload.customer.document,

    // Campos PF
    customer_name: isPF ? contract.payload.customer.name : undefined,
    customer_social_name: isPF ? contract.payload.customer.socialName : undefined,
    customer_social_name_toggle: isPF ? !!contract.payload.customer.socialName : undefined,
    customer_email: contract.payload.customer.email,
    customer_phone: formatToPhone(contract.payload.customer.phone),
    customer_birthdate: contract.payload.customer.birthdate,
    customer_occupation: contract.payload.customer?.customerOccupation
      ? parseOccupationValue(`${contract.payload.customer.customerOccupation}`)
      : undefined,
    customer_pep: contract.payload.customer?.customerPep ? `${contract.payload.customer.customerPep}` : undefined,
    civil_status: contract.payload.customer.civilStatus,
    customer_income: contract.payload.customer.customerIncome,

    pep_document_type: contract.payload?.customer?.pepDetails?.document?.type?.toString(),
    pep_document: contract?.payload?.customer?.pepDetails?.document?.code,
    pep_document_dispatcher: contract?.payload?.customer?.pepDetails?.document?.dispatcher,
    pep_document_expedition_date: contract?.payload?.customer?.pepDetails?.document?.expeditionDate,
    pep_document_expiration_date: contract?.payload?.customer?.pepDetails?.document?.expirationDate,

    pep_brazilian_resident: contract?.payload?.customer?.pepDetails?.brazilianResident,
    pep_cpf: contract?.payload.customer?.pepDetails?.cpf,
    pep_name: contract.payload.customer?.pepDetails?.name,
    pep_relationship_type: contract.payload.customer?.pepDetails?.relationshipType,

    // Campos PJ
    company_name: isPJ ? contract.payload.customer.name : undefined,
    company_email: isPJ ? contract.payload.customer.email : undefined,
    company_phone: isPJ ? contract.payload.customer.phone : undefined,
    foundation_date: isPJ ? contract.payload.customer.birthdate : undefined,
    customer_company_type: contract.payload.customer.company?.type,
    customer_company_netEquity: contract.payload.customer.company?.netEquity,
    customer_company_grossIncome: contract.payload.customer.company?.grossIncome,
    customer_company_controllers: contract.payload.customer.company?.controllers,

    estate_type: contract?.payload?.estate?.type,
    estate_owner: contract?.payload?.estate?.owner,
    estate_address: {
      city: contract?.payload?.estate?.address?.city,
      state: contract?.payload?.estate?.address?.state,
      number: contract?.payload?.estate?.address?.number,
      street: contract?.payload?.estate?.address?.street,
      zipcode: contract?.payload?.estate?.address?.zipcode,
      complement: contract?.payload?.estate?.address?.complement,
      neighborhood: contract?.payload?.estate?.address?.neighborhood,
    },

    coverages: {
      fire: contract?.payload?.coverages?.fire
        ? formatMoneyBRL(contract?.payload?.coverages?.fire)
        : '',
      content: contract?.payload?.coverages?.content
        ? formatMoneyBRL(contract?.payload?.coverages?.content)
        : '',
      eletrical_damages: contract?.payload?.coverages?.eletrical_damages
        ? formatMoneyBRL(contract?.payload?.coverages?.eletrical_damages || 0)
        : 0,
      vehicle_impacts: contract?.payload?.coverages?.vehicle_impacts
        ? formatMoneyBRL(contract?.payload?.coverages?.vehicle_impacts || 0)
        : 0,
      gale: contract?.payload?.coverages?.gale
        ? formatMoneyBRL(contract?.payload?.coverages?.gale || 0)
        : 0,
      civil_responsability: contract?.payload?.coverages?.civil_responsability
        ? formatMoneyBRL(contract?.payload?.coverages?.civil_responsability || 0)
        : 0,
      rental_loss: contract?.payload?.coverages?.rental_loss
        ? formatMoneyBRL(contract?.payload?.coverages?.rental_loss || 0)
        : 0,
      riot: contract?.payload?.coverages?.riot
        ? formatMoneyBRL(contract?.payload?.coverages?.riot || 0)
        : 0,
    },
  }
}

export const plans = ['residential', 'commercial']

export const plansParse = {
  residential: 'Residencial',
  commercial: 'Comercial',
}

export const clauses = ['479', '553', '485', '480', '548']

export const clausesParse = {
  '479': 'Padrão Gratuito',
  '553': 'Intermediário Rede Referenciada - R$ 45,00',
  '485': 'Intermediário Livre Escolha - R$ 115,00',
  '480': 'Total Rede Referenciada - R$ 150,00',
  '548': 'Total Livre Escolha - R$ 260,00',
}

export const coverages = [
  'fire',
  'content',
  'eletrical_damages',
  'vehicle_impacts',
  'gale',
  'civil_responsability',
  'rental_loss',
  'riot',
]

export const coveragesParse = {
  fire: 'Incêndio',
  content: 'Conteúdo',
  eletrical_damages: 'Danos elétricos',
  vehicle_impacts: 'Impacto de veículos',
  gale: 'Vendaval',
  civil_responsability: 'Responsabilidade civil',
  rental_loss: 'Perda de aluguel',
  riot: 'Tumultos',
}

export const includedServicesByClause = value => {
  const free = ['Chaveiro', 'Reparos hidráulicos']
  const middle = [...free, 'Reparos elétricos', 'Help desk telefônico']
  const total = [
    ...middle,
    'Assistência à bike',
    'Assistência em antenas',
    'Atendimento telefônico',
    'Barra de apoio',
    'Central telefônica',
    'Interfone e porteiro eletrônico',
    'Chaveiro - Instalação de fechadura e trava tetra',
    'Chaveiro - Troca de segredo de fechaduras',
    'Conectividade',
    'Conversão de gás para fogão',
    'Desentupimento',
    'Instalação de kit de fixação',
    'Ventilador de teto instalação/reinstalação',
    'Limpeza e desentupimento de calhas e condutores',
    'Mudança de mobiliário',
    'Reparos Linha Branca (refrigeradores, side by side, freezer e frigobar, máquina de lavar roupas, lavar louças e tanquinho; máquina de lavar e secar roupas, secadora de roupas e centrífuga, fogão, forno, cooktop e micro-ondas)',
    'Reparos elétricos',
    'Reparos em ar condicionado',
    'Serviço de telefonia',
    'Substituição de telhas e cumeeiras',
  ]

  return (
    {
      '479': free,
      '553': middle,
      '485': middle,
      '480': total,
      '548': total,
    }[value] || []
  )
}
